import Button from "./button";

export default function Header() {
	return (
		<div className="content absolute top-0 left-0 w-full z-50 flex items-center justify-between py-[1.5rem] md:py-[2rem] px-[2rem] md:px-[8rem] bg-transparent border-solid border-b-[1px] border-[#a5ff2b] md:border-none">
			<div className="w-[120px] md:w-auto">
				<img src="/logo.svg" alt="" />
			</div>
			<div className="flex text-[1.2rem] gap-12 hidden md:flex">
				<div className="flex items-center"><a href="#">Home</a></div>
				<div className="flex items-center"><a href="#">Category</a></div>
				<div className="flex items-center"><a href="#">About Us</a></div>
				<div className="flex items-center"><a href="#">Blog</a></div>
				<div className="flex items-center"><a href="#">Contact Us</a></div>
				<Button>Book Now</Button>
			</div>
			<div className="block md:hidden">
				<img src="/drawer.svg" alt="" />
			</div>
		</div>
	)
}